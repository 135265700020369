<template>
  <div style="background-color: #ffffff;width: 100%;height: 100%">
    <div style="margin-bottom: 30px;padding-top: 20px;text-align: right;padding-right: 30px">
      <el-autocomplete
          size="small"
          style="width: 35%"
          placeholder="请输入搜索内容"
          prefix-icon="el-icon-search"
          clearable
          :fetch-suggestions="searchQuery"
          @clear="clearValue"
          @select="handleSelect"
          v-model="searchValue">
      </el-autocomplete>
    </div>
    <div :id="item.id" v-for="(item,index) in menuList" :key="index" class="card-container">
      <el-card class="card">
        <template #header>
          <div :class="['card-title',selectId == item.id&&'select-item']">
            {{ item.menuName }}
          </div>
        </template>
        <div class="button-container">
          <div :id="items.id" v-for="(items, indexs) in item.children"
               :class="['card-button',selectId == items.id&&'select-item']" :key="indexs"
               @click="skipRouter(items.menuId)">
            {{ items.menuName }}<i class="icon el-icon-arrow-right el-icon--right"></i></div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
export default {
  name: "navigation",
  data() {
    return {
      menuList: [],
      searchValue: '',
      selectId: '',
      searchArr: [],
      timeout: null,
      routerId: "",
      options: []
    }
  },
  mounted() {
    this.menuList = this.$ajax.menuUrl[0].children;
    this.getWorkFlowList()
  },
  watch: {
    $route() {
      if (this.$route.query.Id) {
        let rt = this.$router.routeTags;
        let find = this.options.find((item) => {
          return item.value == this.routerId
        })
        rt.forEach(item => {
          let arr = item.href.split('Id=')
          if (arr[1] == this.routerId && find!=undefined){
            item.meta.typeString = find.label
          }
        })
      }
    }
  },
  methods: {
    async getWorkFlowList() {
      let {data: res} = await this.$ajax.get(this.$appConfig.apiUrls.flowApi + "/api/WorkFlow/GetWorkFlowList")
      if (res.IsSuccess) {
        for (let key in res.Data) {
          this.options.push({value: key, label: res.Data[key]});
        }
      }
    },
    clearValue() {
      this.selectId = ''
      this.searchArr = []
    },
    handleSelect(res) {
      this.selectId = res.id
      let link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.onclick = function () {
        document.getElementById(`${res.id}`).scrollIntoView(true);
      }
      link.click();
      document.body.removeChild(link);
    },
    resetting() {
      this.searchArr = []
    },
    async skipRouter(id) {
      let {data: res} = await this.$ajax({
        url: this.$appConfig.apiUrls.flowApi + '/api/WorkFlow/GetWorkFlowCreateForm?workFlowId=' + id,
        method: 'GET',
      })
      if(res.IsSuccess){
        if(res.Data!=null){
          this.routerId = id
          this.$router.push({
            path: '/project/' + res.Data.Address,
            query: {
              Id: id
            }
          });
        }else{
          ElMessage.warning('建设中')
        }
      }else{
        console.log('error');
      }
    },
    searchQuery(r, cb) {
      if (this.searchValue) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchArr = []
          this.circulate(this.menuList, this.searchValue)
          if (this.searchArr?.length > 0) {
            this.searchArr.forEach((item, i) => {
              item.value = item.menuName
              if (i == this.searchArr?.length - 1) {
                cb(this.searchArr);
              }
            })
          } else {
            cb([])
          }
        }, 500)
      } else {
        cb([])
      }
    },
    circulate(data, value) {
      if (value) {
        let mdb = data.filter(item => {
          return item.menuName.indexOf(value) != -1
        })
        if (mdb?.length > 0) {
          this.searchArr.push(...mdb)
        }
        if (data && value) {
          let mdb1 = []
          data.forEach(item => {
            if (item.children && item.children.length > 0) {
              let mdb2 = null
              mdb1 = this.circulate(item.children, value)
              if (mdb2?.length > 0) {
                mdb1 = mdb2
              }
            }
          })
          if (mdb1?.length > 0) {
            return mdb1
          }
        } else {
          return []
        }
      }
    },
  }
}
</script>

<style scoped>
.card-container {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

.card {
  width: 90%;
  border: none;
  box-shadow: none;
}

.card-title {
  font-size: 25px;
  width: 420px;
}

.card-button {
  position: relative;
  width: 420px;
  margin-left: 0;
  border: none;
  margin-bottom: 20px;
  background-color: #F8F8F8;
  color: #000000;
  font-size: 15px;
  padding: 15px 0;
  margin-right: 35px;
  padding-left: 10px;
}

.card-button:hover {
  background-color: #409eff;
}

.icon {
  position: absolute;
  right: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.select-item {
  background: #409eff !important;
}
</style>
